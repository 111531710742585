<script>
    import { useNavigate, useLocation } from "svelte-navigator";
    import { user } from "../stores/sessionStore";

    const navigate = useNavigate();
    const location = useLocation();
    const hashProps = new URLSearchParams($location.hash.split("#")[1]) 
    console.log(hashProps.get("type"));

    $: if (hashProps.get("type") === "recovery") {
        console.log("token", hashProps.get("access_token"));
        navigate("/recovery", {
            state: { access_token: hashProps.get("access_token") },
            replace: true,
        });
    } else if (hashProps.get("type") === "invite") {
        navigate("/signup", {
            state: { access_token: hashProps.get("access_token") },
            replace: true,
        });
    }

    $: if (!$user && hashProps && !(hashProps.get("type") === "recovery") && !(hashProps.get("type") === "invite")) {
        navigate("/login", {
            state: { from: $location.pathname },
            replace: true,
        });
    }
</script>

{#if $user}
    <slot />
{/if}
