<script lang="ts">
    import B2Downloader from "../components/DownloadComp/b2Downloader";
    import Archive from "../components/DownloadComp/cryptoArchiver";
    // import Keychain from "../components/FileDownloadComp/keychain";
    // import { b64ToArray } from "../components/FileDownloadComp/utils.js";
    // import Zip from "../components/FileDownloadComp/zip";
    // import { transformStream } from "../components/FileDownloadComp/streams";
    // @ts-ignore: Cannot find module
    import FileIcon from "../assets/file-icon.svg";

    import BackButton from "../components/BackButton.svelte";
    import { onMount } from "svelte";
    import ProgressBar from "../components/ProgressBar.svelte";
    import { supabase } from "../utils/supabase";
    import { useNavigate } from "svelte-navigator";

    import type { FilesInfo, FileData } from "../types/types";
    const navigate = useNavigate();

    let downloader: B2Downloader;
    let archive: Archive;
    let metadata = new Promise(() => {}) as Promise<FilesInfo>;

    //let allFilesInfo = {} as FilesInfo;
    let percentComplete = 0;
    let downloadLink: string;
    let zipName: string;
    let fileData: FileData;
    //let keychain: Keychain;
    export let fileStr: string;

    onMount(async () => {
        try {
            let { error } = await getFileInfo(fileStr);
            if (error) {
                console.error(error);
                navigate("/404", { replace: true });
            }
        } catch (error) {
            navigate("/404", { replace: true });
        }
    });

    async function getFileInfo(fileStr: string) {
        const [fileId, password] = fileStr.split("::");

        if (!fileId || !password) {
            return { error: "File does not exist." };
        }

        downloader = new B2Downloader(
            fileId,
            supabase.auth.session().access_token
        );
        archive = new Archive();

        try {
            metadata = new Promise(async (resolve, reject) => {
                let data = await downloader.getInfo();
                fileData = data;
                resolve(
                    await archive.decryptMetadata(
                        atob(data.fileInfo.files),
                        password
                    )
                );
            }) as Promise<FilesInfo>;

            console.log(await metadata);

            zipName = await metadata.then((data) => data.name);
            return { error: null };
        } catch (error) {
            return { error: error };
        }
        /* 

        keychain = new Keychain(password);

        const [data, downloadErr] = await getDownloadData(fileId);
        if (downloadErr) {
            return { error: downloadErr };
        }

        console.log(data.fileInfo);
        const meta = (await keychain.decryptMetadata(
            b64ToArray(atob(data.fileInfo.files))
        )) as FilesInfo;

        allFilesInfo = meta;
        zipName = allFilesInfo.name;
        fileData = data;
        return { error: null }; */
    }

    async function startDownload() {
        if (!fileData || !metadata || !downloader) {
            console.error("Missing argument.");
            return;
        }
        percentComplete = 10;
        let meta = await metadata;

        let downloadStream = await downloader.download();
        console.log(downloadStream, meta);

        let fileObject = await archive.decrypt(
            downloadStream,
            meta,
            null,
            (percent: number) => {
                if (percent > percentComplete) {
                    percentComplete = percent;
                }
            }
        );
        console.log(fileObject);

        /* const blobStream = await downloadFile(
            fileData.authorizationToken,
            fileData.fileUrl
        );
        if (!blobStream) {
            throw new Error("blob error");
        }
        const decryptStream = await keychain.decryptStream(blobStream);

        let zipStream = null;
        let type = allFilesInfo.type;
        let size = allFilesInfo.size;
        if (allFilesInfo.type === "send-archive") {
            const zip = new Zip(allFilesInfo.manifest, decryptStream);
            zipStream = zip.stream;
            type = "application/zip";
            size = zip.size;
        }

        let downloadedBytes = 0;

        const responseStream = transformStream(
            zipStream || decryptStream,
            {
                transform(chunk, controller) {
                    downloadedBytes += chunk.length;
                    percentComplete = Math.floor(
                        (downloadedBytes / allFilesInfo.size) * 100
                    );
                    controller.enqueue(chunk);
                },
            },
            function oncancel() {
                console.log("cancel");
            }
        );

        const headers = {
            "Content-Disposition": `attachment; filename='${encodeURIComponent(allFilesInfo.name)}'`,
            "Content-Type": type,
            "Content-Length": size.toString(),
        };

        const readableStream = new Response(responseStream, { headers });

        const blob = await readableStream.blob();
        console.log(blob); */
        downloadLink = URL.createObjectURL(fileObject.blob);
    }
    /* 
    async function getDownloadData(fileId: string) {
        try {
            let data = await fetch(
                `https://file-api-app.inosida.com/download/?fileId=${fileId}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + supabase.auth.session().access_token,
                    },
                }
            ).then((response) => response.json());

            if (!data) {
                console.log("failed to get data");
                throw new Error();
            }
            console.log(data);
            return [data, null];
        } catch (err) {
            console.log(err);
            return [null, err];
        }
    }

    async function downloadFile(token: string, fileUrl: string) {
        return await fetch(`${fileUrl}?Authorization=${token}`).then(
            (response) => response.body
        );
    } */

    $: if (downloadLink) {
        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = downloadLink;
        link.download = zipName;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        // Remove link from body
        URL.revokeObjectURL(downloadLink);
        document.body.removeChild(link);
    }

    const bytesToSize = (bytes: number) => {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "n/a";
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0) return `${bytes} ${sizes[i]}`;
        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    };
</script>

<div class="nav-2 wf-section">
    <div class="site-container">
        <div class="nav-2-spliter">
            <BackButton>Tillbaka</BackButton>
        </div>
    </div>
</div>
<div class="file-section wf-section">
    <div class="site-container-no-mobile">
        {#await metadata}
            <!-- meta is pending -->
            <div id="submission-body-placeholder" class="submission-body">
                <div class="submission-part">
                    <div class="medium-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="lage-placeholder" />
                </div>
                <div class="gray-line submission-line" />
                <div class="submission-part">
                    <div class="small-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="lage-placeholder" />
                </div>
                <div class="submission-part">
                    <div class="small-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="lage-placeholder" />
                </div>
                <div class="submission-part">
                    <div class="small-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="x-lage-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="x-lage-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="x-lage-placeholder" />
                    <div class="_7px-spacer" />
                    <div class="x-lage-placeholder" />
                </div>
            </div>
        {:then meta}
            <!-- meta was fulfilled -->
            <div class="file-wrapper">
                <div class="file-icon w-embed">
                    <FileIcon />
                </div>
                <div class="file-info">
                    {meta.name} - {bytesToSize(meta.size)}
                </div>
                {#if percentComplete > 0 && percentComplete < 100}
                    <ProgressBar value={percentComplete} />
                {:else if fileData}
                    <div on:click={startDownload} class="button w-button">
                        Ladda ner filer
                    </div>
                {/if}
            </div>
        {:catch error}
            <!-- meta was rejected -->
            <p>Failed to load file</p>
        {/await}
    </div>
</div>
