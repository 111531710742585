<script>
    import MenuButton from "./MenuButton.svelte";
    import { useLocation } from "svelte-navigator";
    import NavLink from "./NavLink.svelte";
    import { Link } from "svelte-navigator";
    import { supabase } from "../utils/supabase";
    import Toast from "./Toast.svelte";
    import InosidaLogo from "../assets/inosida-logo.svg";
    import SignoutIcon from "../assets/signout-icon.svg";
    import { toastMessage } from "../stores/messageStore";
    import { user, forms } from "../stores/sessionStore";
    import { onMount } from "svelte";

    async function handleLogout() {
        const { error } = await supabase.auth.signOut();
        if (error) {
            toastMessage.set({
                status: "error",
                message: "Det gick inte att logga ut.",
            });
        }
    }
    $: innerWidth = 0;
</script>

<svelte:window bind:innerWidth />
<Toast />
<div class="nav wf-section">
    <div class="site-container">
        <div class="nav-wrapper">
            <div class="nav-logo-wrapper">
                <Link to="/" class="nav-logo w-embed">
                    <InosidaLogo />
                </Link>
            </div>
            {#if $user}
                <div class="nav-2-spliter">
                    {#if innerWidth > 768}
                        <div class="left-button-nav-wrapper hide-mobile">
                            <NavLink to="/">Översikt</NavLink>
                            {#if $forms?.length > 0}
                                <NavLink to="/inkorg">Inkorg</NavLink>
                            {/if}
                        </div>
                        <a
                            href
                            on:click|preventDefault={handleLogout}
                            class="logout-button hide-mobile w-inline-block">
                            <div>Logga ut</div>
                            <div class="signout-icon w-embed">
                                <SignoutIcon />
                            </div>
                        </a>
                    {:else}
                        <MenuButton to="/menu">Menu</MenuButton>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
</div>
