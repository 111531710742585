<script>
    import { useNavigate } from "svelte-navigator";
    import HamburgerIcon from "../assets/hamburger-icon.svg";

    const navigate = useNavigate();

    export let to;

    function goBack() {
        navigate(to, { replace: false });
    }
</script>

<a
    href
    on:click|preventDefault={goBack}
    class="hamburger-button hide-desktop w-inline-block">
    <div class="close-hamburger-icon w-embed">
        <HamburgerIcon />
    </div>
</a>
