<script>
    export let spamInbox;
</script>

<div class="form-toggle">
    {#if !spamInbox}
        <div
            on:click={() => {
                spamInbox = false;
            }}
            class="form-toggle-button form-toggle-button-active w-inline-block">
            <div>Inkorg</div>
        </div>
        <div
            on:click={() => {
                spamInbox = true;
            }}
            class="form-toggle-button w-inline-block">
            <div>Skräppost</div>
        </div>
    {:else}
        <div
            on:click={() => {
                spamInbox = false;
            }}
            class="form-toggle-button w-inline-block">
            <div>Inkorg</div>
        </div>
        <div
            on:click={() => {
                spamInbox = true;
            }}
            class="form-toggle-button form-toggle-button-active w-inline-block">
            <div>Skräppost</div>
        </div>
    {/if}
</div>