<script lang="ts">
    import { Link } from "svelte-navigator";
    // @ts-ignore: Cannot find module
    import DownArrowIcon from "../assets/down-arrow-icon.svg";
    // @ts-ignore: Cannot find module
    import SearchIcon from "../assets/search-icon.svg";
    // @ts-ignore: Cannot find module
    import SearchIconMobile from "../assets/search-icon-mobile.svg";
    // @ts-ignore: Cannot find module
    import SearchIconClose from "../assets/search-icon-close.svg";
    // @ts-ignore: Cannot find module
    import RefreshIcon from "../assets/refresh-icon.svg";
    // @ts-ignore: Cannot find module
    import LockedIcon from "../assets/locked-icon.svg";
    // @ts-ignore: Cannot find module
    import UnlockedIcon from "../assets/unlocked-icon.svg";
    import { getSubmissions, getFormsStats } from "../stores/sessionStore";
    import { onDestroy, onMount } from "svelte";
    import SpamToggle from "../components/SpamToggle.svelte";
    import { useNavigate } from "svelte-navigator";

    import type { Submission, FormStats } from "../types/types";

    const navigate = useNavigate();

    export let selected: string;
    let dropdownOpen = false;
    let searchOpen = false;
    let searchQuery = "";

    let loading = true;
    let submissions: Submission[];
    let formsStats: FormStats[];
    let currentForm: FormStats;
    let spamInbox = false;

    let refreshTimer: NodeJS.Timeout;
    let backgroundRefreshTimer: NodeJS.Timer;
    let waitForTimer = false;

    $: loadSubmissions(spamInbox, selected);
    $: if (formsStats && formsStats.length > 0) {
        currentForm = selected
            ? formsStats.filter((item) => {
                  return item.formid == selected;
              })[0]
            : formsStats[0];
    }

    async function loadSubmissions(spam = spamInbox, formid = selected) {
        try {
            loading = true;
            formsStats = await getFormsStats();
            console.log("Stats: ", formsStats);

            let subs = await getSubmissions(
                spam,
                formid ? formid : formsStats[0].formid
            );
            console.log("Submissions: ", subs);
            if (subs === undefined) {
                throw new Error("Not found");
            }
            submissions = subs;
        } catch (error) {
            navigate("/404", { replace: true });
        } finally {
            loading = false;
        }
    }

    function toggleDropdown() {
        dropdownOpen = dropdownOpen ? false : true;
    }

    function toggleSearch() {
        searchOpen = searchOpen ? false : true;
    }

    async function handleSearch() {
        console.log(searchQuery);
        let subs = await getSubmissions(spamInbox, selected);
        submissions = subs.filter(function (submission) {
            let subject = submission.subject?.toLowerCase() || "";
            let dateString = new Date(submission.created_at).toLocaleString();
            let subString = subject + "," + dateString;
            console.log(subString, searchQuery.toLowerCase());
            let result = subString.includes(searchQuery.toLowerCase());
            console.log("Result: ", result);

            return result;
        });
    }

    async function backgroundRefresh() {
        console.log("backgroundRefresh");
        if (!waitForTimer) {
            clearTimeout(refreshTimer);
            waitForTimer = true;
            refreshTimer = setTimeout(() => {
                waitForTimer = false;
            }, 1000 * 30);
            try {
                submissions = await getSubmissions(
                    spamInbox,
                    selected ? selected : formsStats[0].formid
                );
            } catch (error) {
                console.log(error);
            }
        }
    }

    async function refresh() {
        loadSubmissions();
    }

    onMount(() => {
        console.log("Running mount");
        backgroundRefreshTimer = setInterval(backgroundRefresh, 1000 * 60);
    });

    onDestroy(() => {
        console.log("Running destroy");
        clearInterval(backgroundRefreshTimer);
    });
</script>

{#if formsStats && currentForm}
    <div class="nav-2 nav-float wf-section">
        <div class="site-container">
            <div class="nav-2-spliter">
                {#if !searchOpen}
                    <div on:click={toggleDropdown} class="domain-list-button">
                        <div class="domain-list-heading-overflow-hidden">
                            <h1 class="domain-list-heading">
                                {currentForm.name}
                            </h1>
                        </div>
                        <div class="domain-list-button-left-col">
                            {#if currentForm.new > 0}
                                <div class="content-number">
                                    {currentForm.new}
                                </div>
                            {/if}
                            <div class="down-arrow w-embed">
                                <DownArrowIcon />
                            </div>
                        </div>
                        {#if dropdownOpen}
                            <div class="domain-list-dropdown">
                                {#each formsStats as form}
                                    <Link
                                        to={`/inkorg/${form.formid}`}
                                        class="domain-list-dropdown-link w-inline-block">
                                        <div>{form.name}</div>
                                        {#if form.new > 0}
                                            <div class="content-number">
                                                {form.new}
                                            </div>
                                        {/if}
                                    </Link>
                                {/each}
                            </div>
                        {/if}
                    </div>
                {/if}
                <div class="search-wrapper {searchOpen ? '' : 'hide-mobile'}">
                    <div class="search-icon w-embed">
                        <SearchIcon />
                    </div>
                    <input
                        type="text"
                        class="search w-input"
                        maxlength="256"
                        name="Search-2"
                        data-name="Search 2"
                        placeholder="Sök efter formulär"
                        on:keyup={handleSearch}
                        bind:value={searchQuery}
                        id="Search-2" />
                </div>
                <div
                    on:click={toggleSearch}
                    class="mobile-search hide-desktop w-inline-block">
                    {#if searchOpen}
                        <div class="search-icon-mobile w-embed">
                            <SearchIconClose />
                        </div>
                    {:else}
                        <div class="search-icon-mobile w-embed">
                            <SearchIconMobile />
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    </div>
    <div class="forms-toggle-section wf-section">
        <div class="site-container">
            <div class="forms-toggle-wrapper">
                <SpamToggle bind:spamInbox />
                <div on:click={refresh} class="refresh-button w-inline-block">
                    <div class="refresh-icon w-embed">
                        <RefreshIcon />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-submission-section wf-section">
        <div class="site-container-no-mobile">
            <div class="submission-list">
                {#if loading}
                    <div
                        id="submission-button-placeholder"
                        class="form-submission-button w-inline-block">
                        <div class="lage-placeholder" />
                        <div class="date-and-encryption-wrapper">
                            <div class="medium-placeholder" />
                        </div>
                    </div>
                    <div
                        id="submission-button-placeholder"
                        class="form-submission-button w-inline-block">
                        <div class="lage-placeholder" />
                        <div class="date-and-encryption-wrapper">
                            <div class="medium-placeholder" />
                        </div>
                    </div>
                    <div
                        id="submission-button-placeholder"
                        class="form-submission-button w-inline-block">
                        <div class="lage-placeholder" />
                        <div class="date-and-encryption-wrapper">
                            <div class="medium-placeholder" />
                        </div>
                    </div>
                {:else if submissions}
                    {#each submissions as submission}
                        <Link
                            to={`/inkorg/${submission.formid}/${submission.id}`}
                            class="form-submission-button w-inline-block">
                            {#if submission.read === false}
                                <div class="blue-ball" />
                            {/if}
                            <div>{submission.subject || "empty"}</div>
                            <div class="date-and-encryption-wrapper">
                                <div class="gray-text">
                                    {new Date(
                                        submission.created_at
                                    ).toLocaleString()}
                                </div>
                                {#if submission.encrypted === true}
                                    <div class="lock w-embed">
                                        <LockedIcon />
                                    </div>
                                {:else}
                                    <div class="lock w-embed">
                                        <UnlockedIcon />
                                    </div>
                                {/if}
                            </div>
                        </Link>
                    {:else}
                        <div
                            id="submission-button-no-items"
                            class="form-submission-button w-inline-block">
                            <div>Du har för närvarande inga meddelanden</div>
                        </div>
                    {/each}
                {:else}
                    <!-- submissions was rejected -->
                    <div
                        id="submission-button-no-items"
                        class="form-submission-button w-inline-block">
                        <div>Ett fel inträffade</div>
                    </div>
                {/if}
            </div>
        </div>
    </div>
{/if}
