<script lang="ts">
    import { supabase } from "../utils/supabase";
    import { useNavigate, useLocation } from "svelte-navigator";
    import { toastMessage } from "../stores/messageStore";
    import ShowIcon from "../assets/show-icon.svg";
    import HideIcon from "../assets/hide-icon.svg";

    const navigate = useNavigate();
    const location = useLocation();

    let password = "";

    let showPassword = false;

    $: type = showPassword ? "text" : "password";

    function onInput(event: any) {
        password = event.target.value;
    }

    const handleLogin = async () => {
        try {
            const token = $location.state.access_token;
            if (!token) throw new Error("No token");
            const { error } = await supabase.auth.api.updateUser(token, {
                password: password,
            });

            if (error) throw error;
            toastMessage.set({
                status: "success",
                message: "Välkommen!",
            });
            navigate("/", { replace: true });
        } catch (error) {
            toastMessage.set({
                status: "error",
                message: error.error_description || error.message,
            });
        }
    };
</script>

<div class="login-section wf-section">
    <div class="site-container-no-mobile">
        <div class="login-wrapper">
            <form class="login-content" on:submit|preventDefault={handleLogin}>
                <h1 class="login-heading">Skapa ett lösenord</h1>
                <label for="Password" class="field-label">Lösenord</label>
                <div style="position: relative; width: 100%;">
                    <input
                        type={type}
                        value={password}
                        on:input={ onInput }
                        class="login-input w-input"
                        maxlength="256"
                        name="L-senord"
                        data-name="Lösenord"
                        placeholder=""
                        id="Password"
                        style="margin-bottom: 0; padding-right: 35px;"
                        required />
                    <button
                        type="button"
                        style="background-color: transparent;position: absolute;right: 0px;top: 0px;bottom: 0px;padding: 9px;"
                        on:click={() => (showPassword = !showPassword)}
                        > {#if showPassword}
                            <HideIcon />
                        {:else}
                            <ShowIcon />
                        {/if}
                    </button>
                </div>
                <div class="form-spacer" />
                <button class="button w-button">Bekräfta</button>
            </form>
        </div>
    </div>
</div>
