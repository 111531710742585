<script lang="ts">
    //import { user } from "../stores/userStore";
    import { Link } from "svelte-navigator";
    // @ts-ignore
    import MailIcon from "../assets/mail-icon.svg";
    import Analytics from "../components/Analytics.svelte";
    import { getFormsStats } from "../stores/sessionStore";

    let formsStats = getFormsStats();
</script>

<div class="hero-start wf-section">
    <div class="site-container">
        <h1 class="hero-text">Översikt</h1>
    </div>
</div>
<div class="forms-section wf-section">
    <div class="site-container">
        <div class="w-layout-grid forms-grid">
            {#await formsStats}
                <div
                    class="form-link w-node-_3d913ee0-9afe-cc73-b496-d0f224e1899e-fa26e5a0 w-inline-block">
                    <div class="form-link-mail">
                        <div class="medium-placeholder" />
                    </div>
                    <div class="gray-line" />
                    <div class="form-link-info">
                        <div class="form-link-info-block">
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                        </div>
                        <div class="form-link-info-block">
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                        </div>
                        <div class="form-link-info-block">
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                            <div class="_10px-spacer" />
                            <div class="small-placeholder" />
                        </div>
                    </div>
                </div>
            {:then result}
                {#each result as form}
                    <Link
                        to={`/inkorg/${form.formid}`}
                        class="form-link w-inline-block">
                        <div class="form-link-mail">
                            <div class="mail-icon w-embed">
                                <MailIcon />
                            </div>
                            <div class="form-link-mail-text">
                                {form.name}
                            </div>
                        </div>
                        <div class="gray-line" />
                        <div class="form-link-info">
                            <div class="form-link-info-block">
                                <div class="small-text">Olästa</div>
                                <div class="big-text">
                                    {form.new}
                                </div>
                            </div>
                            <div class="form-link-info-block">
                                <div class="small-text">Spam</div>
                                <div class="big-text">
                                    {form.spam}
                                </div>
                            </div>
                            <div class="form-link-info-block">
                                <div class="small-text">Totalt</div>
                                <div class="big-text">
                                    {form.total}
                                </div>
                            </div>
                        </div>
                    </Link>
                {/each}
            {:catch error}
                {error}
            {/await}
        </div>
    </div>
</div>
<div class="tba wf-section">
    <div class="site-container">
        <img src="/images/TBA.jpg" loading="lazy" alt="" />
    </div>
</div>
