<script>
    import BackButton from "../components/BackButton.svelte";
</script>

<div class="nav-2 wf-section">
    <div class="site-container">
        <div class="nav-2-spliter">
            <BackButton>Tillbaka</BackButton>
        </div>
    </div>
</div>
<h1 class="message">404 Sidan kunde inte hittas.</h1>

<style>
    .message {
        margin-inline: auto;
        padding: 3rem;
        width: max-content;
    }
</style>
