<script lang="ts">
    import { supabase } from "../utils/supabase";
    import { useNavigate, useLocation } from "svelte-navigator";
    import { user } from "../stores/sessionStore";
    import { toastMessage } from "../stores/messageStore";

    const navigate = useNavigate();
    const location = useLocation();

    let username: string;
    let password: string;

    let loading = false;

    const handleLogin = async () => {
        try {
            loading = true;
            const from =
                ($location.state && $location.state.from) !== undefined
                    ? $location.state.from
                    : "/";
            const { error } = await supabase.auth.signIn({
                email: username,
                password: password,
            });
            if (error) throw error;
            toastMessage.set({
                status: "success",
                message: "Välkommen!",
            });
            console.log($location.state.from);
            navigate(from, { replace: true });
        } catch (error) {
            toastMessage.set({
                status: "error",
                message: error.error_description || error.message,
            });
        } finally {
            loading = false;
        }
    };

    $: if ($user) {
        if (($location.state && $location.state.from) !== undefined) {
            console.log($location.state.from);
            navigate($location.state.from, { replace: true });
        }
    }
</script>

<div class="login-section wf-section">
    <div class="site-container-no-mobile">
        <div class="login-wrapper">
            <form class="login-content" on:submit|preventDefault={handleLogin}>
                <h1 class="login-heading">Logga in för att se dina formulär</h1>
                <label for="Username" class="field-label">Användarnamn</label
                ><input
                    type="email"
                    bind:value={username}
                    class="login-input w-input"
                    maxlength="256"
                    name="Anv-ndarnamn"
                    data-name="Användarnamn"
                    placeholder=""
                    id="Username"
                    required /><label for="Password" class="field-label"
                    >Lösenord</label
                ><input
                    type="password"
                    bind:value={password}
                    class="login-input w-input"
                    maxlength="256"
                    name="L-senord"
                    data-name="Lösenord"
                    placeholder=""
                    id="Password"
                    required />
                <a
                    href="mailto:kontakt@inosida.se?subject=Jag%20har%20gl%C3%B6mt%20l%C3%B6senordet%20till%20inosida%20web"
                    class="forgot-link">Har du glömt lösenordet?</a>
                <div class="form-spacer" />
                <button class="button w-button">Logga In</button>
            </form>
        </div>
    </div>
</div>
