<script>
    import { user, forms } from "../stores/sessionStore";
    import { useNavigate } from "svelte-navigator";
    import { Link } from "svelte-navigator";
    import InosidaLogo from "../assets/inosida-logo.svg";
    import CloseIcon from "../assets/close-icon.svg";
    import HomeIcon from "../assets/home-icon.svg";
    import MailIcon from "../assets/mail-icon.svg";
    import SignoutIcon from "../assets/signout-icon.svg";
    import { getFormsStats } from "../stores/sessionStore";
    import { supabase } from "../utils/supabase";

    let formsStats = getFormsStats();

    const navigate = useNavigate();

    function click(to) {
        navigate(to, { replace: true });
    }

    function goBack() {
        navigate(-1, { replace: true });
    }

    async function handleLogout() {
        const { error } = await supabase.auth.signOut();
        if (error) {
            toastMessage.set({
                status: "error",
                message: "Det gick inte att logga ut.",
            });
        }
    }
</script>

<svelte:body class="hamburger-body" />
<div class="nav wf-section">
    <div class="site-container">
        <div class="nav-wrapper">
            <div class="nav-logo-wrapper">
                <Link to="/" class="nav-logo w-embed">
                    <InosidaLogo />
                </Link>
            </div>
            <div class="nav-2-spliter">
                <div on:click={goBack} class="hamburger-button w-inline-block">
                    <div class="close-hamburger-icon w-embed">
                        <CloseIcon />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{#if $user}
    <div class="hamburger-content-section wf-section">
        <div class="site-container-no-mobile">
            <div
                on:click={() => {
                    click("/");
                }}
                class="hamburger-hero">
                <div class="hamburger-menu-img w-embed">
                    <HomeIcon />
                </div>
                <h1 class="hamburger-heading">Översikt</h1>
            </div>
            {#if $forms?.length > 0}
                <div class="hamburger-forms">
                    <div
                        class="hamburger-forms-hero"
                        on:click|preventDefault={() => {
                            click("/inkorg");
                        }}>
                        <div class="hamburger-menu-img w-embed">
                            <MailIcon />
                        </div>
                        <h1 class="hamburger-heading blue-heading">Inkorgar</h1>
                    </div>
                    <div class="gray-line hide-desktop" />
                    {#await formsStats then value}
                        {#each value as form}
                            <div
                                on:click|preventDefault={() => {
                                    click(`/inkorg/${form.formid}`);
                                }}
                                class="hamburger-form-submission-button w-inline-block">
                                <div class="hamburger-form-text">
                                    {form.name}
                                </div>
                                {#if form.new > 0}
                                    <div class="content-number">
                                        {form.new}
                                    </div>
                                {/if}
                            </div>
                        {/each}
                    {:catch error}
                        {error}
                    {/await}
                </div>
            {/if}
            <div class="hamburger-menu-button-wrapper">
                <div
                    on:click|preventDefault={handleLogout}
                    class="button hamburger-menu-button w-inline-block">
                    <div>Logga ut</div>
                    <div class="signout-icon w-embed">
                        <SignoutIcon />
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}
