async function downloadInfo(fileId, accessToken) {
    let data = await fetch(
        `https://file-api-app.inosida.com/download/?fileId=${fileId}`,
        {
            headers: {
                Authorization:
                    "Bearer " + accessToken,
            },
        }
    ).then((response) => response.json());

    if (!data) {
        console.log("failed to get data");
        throw new Error();
    }

    return data;
}

async function downloadFile(token, fileUrl) {
    return await fetch(`${fileUrl}?Authorization=${token}`).then(
        (response) => response.body
    );
}


class B2Downloader {
    constructor(fileId, accessToken) {
        this.fileId = fileId;
        this.accessToken = accessToken;

        this.b2AccessToken = null;
        this.b2FileUrl = null;
    }

    async getInfo() {
        let data = await downloadInfo(this.fileId, this.accessToken);
        console.log(data);
        this.b2AccessToken = data.authorizationToken;
        this.b2FileUrl = data.fileUrl;
        return data;
    }
    
    download() {
        return downloadFile(this.b2AccessToken, this.b2FileUrl);
    }
}


export default B2Downloader;