<script>
    import { Router, Route } from "svelte-navigator";
    import Login from "./routes/Login.svelte";
    import Home from "./routes/Home.svelte";
    import Inbox from "./routes/Inbox.svelte";
    import PrivateRoute from "./utils/PrivateRoute.svelte";
    import Navbar from "./components/Navbar.svelte";
    import Submission from "./routes/Submission.svelte";
    import Menu from "./routes/Menu.svelte";
    import FileDownload from "./routes/FileDownload.svelte";
    import NotFound from "./routes/NotFound.svelte";
    import Recovery from "./routes/Recovery.svelte";
    import Signup from "./routes/Signup.svelte";

    
    
</script>

<Router>
    <main>
        <Route path="login">
            <Navbar />
            <Login />
        </Route>
        <Route path="recovery">
            <Navbar />
            <Recovery />
        </Route>
        <Route path="signup">
            <Navbar />
            <Signup />
        </Route>
        <PrivateRoute path="filer/*">
            <Navbar />
            <Route path=":id" let:params>
                <FileDownload fileStr={params.id} />
            </Route>
        </PrivateRoute>
        <PrivateRoute path="/">
            <Navbar />
            <Home />
        </PrivateRoute>
        <PrivateRoute path="/menu">
            <Menu />
        </PrivateRoute>
        <PrivateRoute path="inkorg/*">
            <Navbar />
            <Route path="/">
                <Inbox />
            </Route>
            <Route path=":formId" let:params>
                <Inbox selected={params.formId} />
            </Route>
            <Route path=":formId/:id" let:params>
                <Submission id={params.id} />
            </Route>
        </PrivateRoute>
        <Route path="*">
            <Navbar />
            <NotFound />
        </Route>
    </main>
</Router>
