<script lang="ts">
    import { tweened } from "svelte/motion";

    export let value = 0;
    let progress = tweened(0);

    $: progress.set(value < 100 ? value : 100);
</script>

<div class="progress">
    <div class="progress-bar">
        <div class="progress-value" style="width: {$progress}%;" />
    </div>
</div>

<style>
    .progress {
        width: 150px;
        border-radius: 20px;
        margin-bottom: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 21px;
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background-color: #f9f9f9;
        border-radius: 20px;
    }

    .progress-bar .progress-value {
        width: 0%;
        height: 100%;
        background-color: #2d3262;
        border-radius: 20px;
    }
</style>
