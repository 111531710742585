<script>
    import { useNavigate } from "svelte-navigator";
    import BackIcon from "../assets/back-icon.svg";
    
    const navigate = useNavigate();

    function goBack() {
        navigate(-1, { replace: true });
    }
</script>

<div on:click={goBack} class="back-button w-inline-block">
    <div class="back-vector w-embed">
      <BackIcon />
    </div>
    <div><slot /></div>
</div>
