<script lang="ts">
    import BackButton from "../components/BackButton.svelte";
    import { onMount } from "svelte";
    import * as openpgp from "openpgp";
    import { getSubmission, updateSubmission } from "../stores/sessionStore";
    import { Link, useNavigate } from "svelte-navigator";
    import { toastMessage } from "../stores/messageStore";

    import type { SubmissionWCreds } from "../types/types";

    // @ts-ignore: Cannot find module
    import TrashIcon from "../assets/trash-icon.svg";

    const navigate = useNavigate();

    export let id: string;

    let promptPassword = false;
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    let data = new Promise(() => {}) as Promise<SubmissionWCreds>;

    onMount(async () => {
        try {
            let submission = await getSubmission(id);
            if (submission.encrypted) {
                data = decryptMessage(submission);
            } else {
                data = Promise.resolve(submission);
            }
        } catch (error) {
            navigate("/404", { replace: true });
        }
    });

    async function decryptMessage(submission: SubmissionWCreds) {
        if (!("passphrase" in submission.encryption)) {
            promptPassword = true;
            return submission;
        }
        const privateKey = await openpgp.decryptKey({
            privateKey: await openpgp.readPrivateKey({
                armoredKey: atob(submission.encryption.privateKey),
            }),
            passphrase: submission.encryption.passphrase,
        });

        const message = await openpgp.readMessage({
            armoredMessage: submission.data[0].value, // parse armored message
        });
        const { data: decrypted } = (await openpgp.decrypt({
            message,
            decryptionKeys: privateKey,
        })) as { data: string };
        try {
            let messageJson = JSON.parse(decrypted);
            console.log(messageJson);
            // get the index for item in messageJson that has the "key" === "_fileKey"
            let index = messageJson.findIndex(
                (item) => item.key === "_fileKey"
            );

            if (submission.fileId && index !== -1) {
                submission.fileKey = messageJson[index].value;
                messageJson.splice(index, 1);
            }
            submission.data = messageJson;
        } catch (error) {
            submission.data = [
                {
                    key: "Encrypted",
                    value: decrypted,
                },
            ];
        }
        return submission;
    }

    async function archiveSubmission() {
        const { error } = await updateSubmission(id, { archived: true, archive_timestamp: (new Date()).toISOString() });
        if (error) {
            toastMessage.set({
                status: "error",
                message: "Kunde inte ta bort meddelande.",
            });
            return;
        }
        toastMessage.set({
            status: "success",
            message: "Meddelandet har tagits bort.",
        });
        navigate(-1);
    }
</script>

<div class="nav-2 wf-section">
    <div class="site-container">
        <div class="nav-2-spliter">
            <BackButton>Tillbaka</BackButton>
        </div>
    </div>
</div>
<div class="submisson-section wf-section">
    <div class="site-container-no-mobile">
        <div class="submission-wrapper">
            {#await data}
                <div id="submission-body-placeholder" class="submission-body">
                    <div class="submission-part">
                        <div class="medium-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="lage-placeholder" />
                    </div>
                    <div class="gray-line submission-line" />
                    <div class="submission-part">
                        <div class="small-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="lage-placeholder" />
                    </div>
                    <div class="submission-part">
                        <div class="small-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="lage-placeholder" />
                    </div>
                    <div class="submission-part">
                        <div class="small-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="x-lage-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="x-lage-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="x-lage-placeholder" />
                        <div class="_7px-spacer" />
                        <div class="x-lage-placeholder" />
                    </div>
                </div>
            {:then value}
                <div class="submission-body">
                    <div class="submission-part">
                        <div class="gray-text">
                            {new Date(value.created_at).toLocaleString()}
                        </div>
                        <h1 class="form-hero-h1">
                            {value?.subject ||
                                "Nytt meddelande från din hemsida"}
                        </h1>
                        <div on:click={archiveSubmission} class="trash-button w-inline-block"><div class="trash-text">Radera</div><div class="trash-vector w-embed"><TrashIcon /></div></div>
                    </div>
                    <div class="gray-line submission-line" />
                    {#if "data" in value}
                        {#each value.data as field}
                            {#if emailPattern.test(field.value)}
                                <div class="submission-part">
                                    <div class="gray-text">{field.key}:</div>
                                    <div class="_3px-spacer" />
                                    <a
                                        href={`mailto:${field.value}${
                                            value?.subject
                                                ? "?subject=Re: " +
                                                  value.subject
                                                : ""
                                        }`}
                                        class="submission-paragraph hidden-link"
                                        >{field.value}</a>
                                </div>
                            {:else}
                                <div class="submission-part">
                                    <div class="gray-text">{field.key}:</div>
                                    <div class="_3px-spacer" />
                                    <pre
                                        style="display:block;font:inherit;margin: 0;white-space: pre-wrap;white-space: -moz-pre-wrap;white-space: -pre-wrap;white-space: -o-pre-wrap;word-wrap: break-word;"
                                        class="submission-paragraph">{field.value}</pre>
                                </div>
                            {/if}
                        {/each}
                    {/if}
                    {#if value.fileId != null}
                        <div class="submission-part">
                            <div class="gray-text">Filer:</div>
                            <div class="_3px-spacer" />
                            <Link
                                to={`/filer/${value.fileId}::${value.fileKey}`}
                                class="blue-text-link">Ladda ner filer</Link>
                        </div>
                    {/if}
                </div>
            {:catch error}
                {error}
            {/await}
        </div>
    </div>
</div>

<style>
    .hidden-link {
        text-decoration: none;
        color: inherit;
    }
</style>
