<script lang="ts">
    import { toastMessage } from "../stores/messageStore";
    // @ts-ignore
    import CloseToastIcon from "../assets/close-toast-icon.svg";

    let message: string;
    let status: string;
    let showMessage = false;
    let messageTimer: NodeJS.Timeout;

    $: if ($toastMessage) {
        message = $toastMessage.message;
        status = $toastMessage.status;
        showMessage = true;
        messageTimer = setTimeout(() => {
            toastMessage.set(null);
            showMessage = false;
        }, 5000);
    }

    function closeToast() {
        clearTimeout(messageTimer);
        toastMessage.set(null);
        showMessage = false;
    }

    function handleMouseOver() {
        clearTimeout(messageTimer);
    }

    function handleMouseOut() {
        clearTimeout(messageTimer);
        messageTimer = setTimeout(() => {
            toastMessage.set(null);
            showMessage = false;
        }, 3000);
    }
</script>

{#if showMessage}
    <div
        class="toast"
        on:blur
        on:focus
        on:mouseover={handleMouseOver}
        on:mouseout={handleMouseOut}>
        {#if status === "error"}
            <div class="toast-modal error">
                <div on:click={closeToast} class="close-toast w-inline-block">
                    <div class="close-toast-icon w-embed">
                      <CloseToastIcon />
                    </div>
                </div>
                <div>{message}</div>
            </div>
        {:else}
            <div class="toast-modal no-error">
                <div on:click={closeToast} class="close-toast w-inline-block">
                    <div class="close-toast-icon w-embed">
                      <CloseToastIcon />
                    </div>
                </div>
                <div>{message}</div>
            </div>
        {/if}
    </div>
{/if}
