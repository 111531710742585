import { writable } from "svelte/store";
import { supabase } from "../utils/supabase";
export const user = writable(null);
export const forms = writable(null);
export const formsStats = writable(null);
user.set(supabase.auth.user());
supabase.auth.onAuthStateChange((_, session) => {
    console.log("setting user");
    user.set(session === null || session === void 0 ? void 0 : session.user);
    getForms();
});
export async function getForms() {
    let { data, error } = (await supabase.from("forms").select("*"));
    if (error) {
        return console.error(error);
    }
    forms.set(data);
    return data;
}
getForms();
export async function getSubmissions(spam, formId, archived = false) {
    let { data, error } = (await supabase
        .from("submissions")
        .select("*")
        .eq("formid", formId)
        .eq("spam", spam)
        .eq("archived", archived)
        .order("created_at", { ascending: false }));
    if (error) {
        throw new Error(error.toString());
    }
    return data;
}
export async function getFormsStats() {
    let { data, error } = (await supabase
        .rpc("get_forms_stats")
        .order("name", { ascending: true }));
    if (error) {
        throw new Error(error.toString());
    }
    formsStats.set(data);
    return data;
}
export async function getSubmission(id) {
    let { data, error } = (await supabase
        .from("submissions")
        .select(`
        created_at,
        subject,
        encrypted,
        data,
        fileId,
        fileKey,
        encryption:formid (
          encryption->privateKey,
          encryption->passphrase
        )
      `)
        .eq("id", id)
        .single());
    if (error) {
        throw new Error(error.toString());
    }
    let updateRes = await supabase
        .from("submissions")
        .update({ read: true }, {
        returning: "minimal", // Don't return the value after inserting
    })
        .eq("id", id);
    if (updateRes.error) {
        throw new Error(updateRes.error.toString());
    }
    return data;
}
export async function updateSubmission(id, updates) {
    let updateRes = await supabase
        .from("submissions")
        .update(updates, {
        returning: "minimal", // Don't return the value after inserting
    })
        .eq("id", id);
    if (updateRes.error) {
        return { error: updateRes.error };
    }
    return { error: null };
}
var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
